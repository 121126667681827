import { useMemo } from 'react'

import { type SimpleSelectOption } from '@workwave-tidal/tidal/components'
import { BulkSelect } from '@/components/BulkSelect'

import { type FormFields, type FormErrors } from '../../../formFields'
import { useTexts } from '../hooks/useTexts'

export function MobileFutureDaysVisibility() {
  const texts = useTexts()
  const options = useMobileFutureDaysVisibilityOptions()

  return (
    <BulkSelect<'mobileFutureDaysVisibility', string, FormFields, FormErrors>
      name="mobileFutureDaysVisibility"
      testId="mobileFutureDaysVisibility"
      label={texts.labels.mobileFutureDaysVisibility}
      mixedLabel={texts.labels.mixed}
      validateOn="change blur"
      options={options}
    />
  )
}

function useMobileFutureDaysVisibilityOptions() {
  const texts = useTexts()

  return useMemo<SimpleSelectOption<string>[]>(() => {
    return [
      {
        value: '-1',
        label: texts.values.mobileFutureDaysVisibility(-1),
      },
      {
        value: '3',
        label: texts.values.mobileFutureDaysVisibility(3),
      },
    ]
  }, [texts])
}
