import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    labels: {
      mobileFutureDaysVisibility: translate({
        id: 'form.drivers.view.labels.mobileFutureDaysVisibility',
      }),
      summarizeFutureMobileRoute: translate({
        id: 'form.drivers.view.labels.summarizeFutureMobileRoute',
      }),
      mixed: translate({ id: 'global.mixed' }),
    },
    driverAppTitle: translate({ id: 'form.drivers.driverApp.title' }),
    routeViewPreferences: translate({ id: 'form.drivers.driverApp.routeViewPreferences' }),
    values: {
      mobileFutureDaysVisibility: (
        mobileFutureDaysVisibility: uui.domain.client.rm.MobileFutureDaysVisibility | 'mixed',
      ) => {
        switch (mobileFutureDaysVisibility) {
          case 'mixed':
            return translate({ id: 'global.mixed' })
          case -1:
            return translate({
              id: 'form.drivers.view.values.mobileFutureDaysVisibility.allDispatchedDays',
            })
          case 3:
            return translate({
              id: 'form.drivers.view.values.mobileFutureDaysVisibility.todayAndNextTwoDays',
            })
          default:
            return mobileFutureDaysVisibility
        }
      },
      summarizeFutureMobileRoute: (summarizeFutureMobileRoute: boolean | 'mixed') => {
        switch (summarizeFutureMobileRoute) {
          case 'mixed':
            return translate({ id: 'global.mixed' })
          case true:
            return translate({ id: 'form.drivers.view.values.summarizeFutureMobileRoute.summary' })
          case false:
            return translate({
              id: 'form.drivers.view.values.summarizeFutureMobileRoute.fullDetails',
            })
        }
      },
    },
    title: (count: number) =>
      translate({
        id: 'form.drivers.edit.bulk.header.title',
        values: { count },
      }),

    helpers: {
      trackDriver: translate({ id: 'form.drivers.edit.fields.trackDriver.helper' }),
      summarizeFutureMobileRoute: translate({
        id: 'form.drivers.edit.fields.summarizeFutureMobileRoute.helper',
      }),
    },

    footer: {
      save: translate({ id: 'form.drivers.edit.footer.save' }),
      cancel: translate({ id: 'form.drivers.edit.footer.cancel' }),
    },
  }))

  return api
}
