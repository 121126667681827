import { useMemo } from 'react'
import { SimpleSelect } from '@workwave-tidal/tidal/form-ui'
import { type SimpleSelectOption } from '@workwave-tidal/tidal/components'

import { type FormFields, type FormErrors } from '../../../formFields'
import { useTexts } from '../hooks/useTexts'

export function MobileFutureDaysVisibility() {
  const texts = useTexts()
  const options = useMobileFutureDaysVisibilityOptions()

  return (
    <SimpleSelect<'mobileFutureDaysVisibility', string, FormFields, FormErrors>
      name="mobileFutureDaysVisibility"
      testId="mobileFutureDaysVisibility"
      label={texts.labels.mobileFutureDaysVisibility}
      validateOn="change blur"
      options={options}
    />
  )
}

function useMobileFutureDaysVisibilityOptions() {
  const texts = useTexts()

  return useMemo<SimpleSelectOption<string>[]>(() => {
    return [
      {
        value: '-1',
        label: texts.values.mobileFutureDaysVisibility(-1),
      },
      {
        value: '3',
        label: texts.values.mobileFutureDaysVisibility(3),
      },
    ]
  }, [texts])
}
